/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { resolve } from 'url';
import { useStaticQuery, graphql } from 'gatsby';
import config from '../config';

interface Props {
  description?: string;
  title: string;
  lang?: string;
  meta?: object[];
  path?: string;
}

const SEO: React.FC<Props> = function (props) {
  const { description, lang = 'en', meta = [], title, path = '/' } = props;

  const metaTitle =
    path === '/'
      ? config.siteMetadata.title
      : `${title} | ${config.siteMetadata.title}`;
  const metaDescription = description || config.siteMetadata.description;

  const jsonLDSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Singapore Drama Educators Association',
    description: metaDescription,
    url: config.siteMetadata.siteUrl,
    logo: resolve(config.siteMetadata.siteUrl, 'logo-meta.png'),
    sameAs: [
      'https://sg.linkedin.com/company/singapore-drama-educators-association',
      'https://www.facebook.com/SDEAofficial',
      'https://twitter.com/SDEAofficial',
      'https://www.youtube.com/channel/UCovUdSPWraWn5tAlcnu57MQ',
      'https://www.instagram.com/sdeaofficial/?hl=en',
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'hello@sdea.org.sg',
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={path === '/' ? '%s' : `%s | ${config.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: resolve(config.siteMetadata.siteUrl, 'logo-meta.png'),
        },
        {
          property: 'og:url',
          content: resolve(config.siteMetadata.siteUrl, path ?? '/'),
        },
        {
          property: 'og:site_name',
          content: config.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: 'twitter:image',
          content: resolve(config.siteMetadata.siteUrl, 'logo-meta.png'),
        },
        {
          name: `twitter:creator`,
          content: config.siteMetadata.author,
        },
        {
          name: 'twitter:url',
          content: resolve(config.siteMetadata.siteUrl, path ?? '/'),
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="canonical"
        href={resolve(config.siteMetadata.siteUrl, path ?? '/')}
      />
      <script type="application/ld+json">{JSON.stringify(jsonLDSchema)}</script>
    </Helmet>
  );
};

export default SEO;
