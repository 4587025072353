const config = {
  siteMetadata: {
    title: 'Singapore Drama Educators Association',
    siteUrl: 'https://www.sdea.org.sg',
    description:
      'Advocating for the value and practice of drama/theatre in performance, education, and community',
  },
};

export default config;
